@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Lato', Arial, Helvetica, sans-serif;
}

.home,
.reports,
.products,
.team,
.support {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
  background-color: azure;
}

.aboutus{
  background-color: azure;
  padding-left: 1.1cm;
}

.a-heading {
  color: #1f79aa;
  padding-top: .5cm;
  align-items: center;
}

.a-text{
  color: rgb(52, 36, 36);
  margin: 5px auto;
  padding: 10px;
  font-size: 20px;
}

.a-h-text{
  color: rgb(27, 11, 165);
  margin: 5px auto;
  padding: 10px;
  font-size: 20px;
}

blockquote {
  font-style: italic;
  color: #555;
  border-left: 5px solid #ccc;
  margin: 20px;
  padding-left: 15px;
}
blockquote footer {
  text-align: right;
  font-size: smaller;
  color: #999;
}

.h-heading {
  color: rgb(27, 11, 165);
  padding-top: 2cm;
  align-items: flex-end;
  font-size: 25px;
  width: 100%;
  height: 15cm;
  background-repeat: no-repeat;
  background-size: cover; /* Cover the entire width of the screen */
  image-resolution: 500px;
}

.h-text{
  color: rgb(52, 36, 36);
  background-size: cover;
  font-size: 20px;
  width: 100%;
  height: 80%;
  image-resolution: 500px;
}

.SuperApp{
  background-color: azure;

}
/*
.h-heading {
  color: rgb(27, 11, 165);
  padding-top: 2cm;
  align-items: flex-end;
  font-size: 25px;
  width: 100%;
  height: 100vh; 
  background-repeat: no-repeat;
  background-size: cover; 
} 
*/
.container {
/*  max-width: 1200px; */
  margin: 0 auto;
/*  padding: 0 20px;  Add padding to the sides for better readability */
}

.home-content {
  background-color: rgb(43, 45, 45);
  padding: 2px;
  padding-left: 21cm; /* Adjusted for laptop/desktop view */
  background-image: url("../components/Home.png");
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 75vh; /* Set minimum height to ensure content visibility */
  font-size: 25px;
  padding-top: 130px;
  color: blue;
}

footer {
  background-color: #333;
  color: #fff;
  padding: 20px;
  text-align: center;
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
}

.social-icons {
  margin-top: 20px;
}

.social-icons a {
  display: inline-block;
  margin-right: 10px;
}

.social-icons img {
  width: 30px;
  height: 30px;
}

/* Media queries for mobile responsiveness */
@media only screen and (max-width: 768px) {
  .content {
    padding-left: 20px; /* Adjusted for mobile view */
    font-size: 0.8cm; /* Adjusted font size for mobile view */
  }
}

/* AboutUs.css */
.about-container {
  display: flex;
  background-color: azure;
}

.image-container {
  flex: 1;
  max-width: 50%; /* Adjust the maximum width as needed */
  height: auto; /* Ensure aspect ratio is maintained */
}

.image-container img {
  width: 65%; /* Ensure the image takes up the entire container width */
  height: 100%; /* Ensure aspect ratio is maintained */
  padding-left: 150px;
  padding-top: 10px;
}

.description-container h4{
  color: #1f79aa;
  font-size: 20px;
  font-family: Arial, Helvetica, sans-serif;
}

.description-container {
  flex: 1;
  padding: 0 20px; /* Adjust padding as needed */
  padding-top: 100px;
}

.description-container h1 {
  margin-top: 0; /* Remove default margin */
}


