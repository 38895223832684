.contactus {
    background-color: azure;
}
.todoForm{
    background-color: white;
    border-radius: 8px;
    padding: 20px;
    width: flex;
    height: flex;
    margin: 0 auto;
    margin-top: 50px;
    box-shadow: 0 4px 6px rgb(18, 1, 1);
}
.modernInput{
    display: flexbox;
    width: 50%;
    border: none;
    font-size: 16px;
}
modernInput:focus{
    outline: none;
}
.modernButton{
    display: inline-block;
    border: none;
    border-radius: 4px;
    background-color: rgb(93, 128, 55);
    padding: 12px;
    position: 10px;
    color: white;
    cursor: pointer;
}
.cimage{
  align-items: center;
    width: 100%;
    height: flex;
}
.t-text{
    color: #478eb4;
    font-family: 'Lato', sans-serif;
    text-align: center;
}
.c-screen{
    background-color: azure;
    display: flex;
    background-image: url("contactus-1.jpeg");
    background-repeat: no-repeat;
    min-height: 75vh;
    background-size: cover; 
    text-align: center;
}  

  