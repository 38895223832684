.s-title {
    padding-top: .3cm;
    color: #171792;
    text-align: start;
    padding-left: .2cm;
}
.tech-text {
    font-size: 18px;
    font-family: myriad-pro, Arial;;
    color: rgb(49, 34, 34);
    padding-left: .5cm;
    padding-top: .2cm;
  }
  
  .content {
    margin: 5px auto;
    padding: 10px;
    background-color:azure;
    color: whitesmoke;
  }

  .tech-list{
  /*  background-image: url("./homepage-1.jpeg"); */
    background-color: azure;
    background-repeat: no-repeat;
    background-size: cover;
  }
 
  /* blog previews / list */
  .blog-preview {
    margin: 20px 5;
    padding-left: .5cm;
    color: rgb(29, 13, 13);
  }

  .blog-preview:hover {
    box-shadow: 12px 13px 15px 1px rgba(0,0,0,0.1);
  }

.sub-title {
    font-size: 20px;
    color: rgb(109, 19, 19);
    padding-left: .5cm;
    padding-top: .3cm;
  }
  
 
   