@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');

/* base styles */
* {
  margin: 20px;
  font-family: "Quicksand";
  
 /* color: rgb(131, 14, 14); */
}
.navbar {
  padding: 20px;
  display: flex;
  align-items: center;
  max-width: 100%;
  margin: 0 auto;
  border-bottom: 1px solid #d99309;
  background-color:#29326e;
  /* #d99309; */
}
.navbar h1 {
  color: whitesmoke;
}
.navbar .links {
  margin-left: auto;
}
.navbar a {
  margin-left: 16px;
  text-decoration: none;
  padding: 6px;
}
.navbar a:hover {
  color: #f1356d;
}

.App{
  background-color: azure;
  
}

