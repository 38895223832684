.contactForm {
    background-color: #f0f0f0;
    border-radius: 8px;
    padding: 20px;
    width: 500px;
    margin: 0 auto;
   /* margin-top: 20px; */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
.contactForm h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
.contactForm input[type='text'],
.contactForm input[type='email'],
.contactForm textarea {
    width: 100%;
    padding: 10px;
    margin-top: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
  }
  
.contactForm textarea {
    height: 350px;
  }
  
.contactForm input[type='submit'] {
    width: 100%;
    background-color: #4caf50;
    color: white;
    padding: 10px 0;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    padding-top: 10px;
  }
  
.contactForm input[type='submit']:hover {
    background-color: #45a049;
  }
  
.contactForm input[type='submit']:focus {
    outline: none;
  }
  
.contactForm .form-group {
    margin-bottom: 20px;
  }
  
.contactForm .form-group:last-child {
    margin-bottom: 0;
  }
.modernButton{
    display: flex;
    border: none;
    border-radius: 4px;
    background-color: rgb(93, 128, 55);
    padding: 12px;
    position: 10px;
    color: white;
    cursor: pointer;
}  

.t-text p {
  color: red; /* Change the color to suit your design */
  align-items: left;  /* Adjust the spacing between the label and the asterisk */
}

.success {
  color: rgb(16, 91, 91);
}