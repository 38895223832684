.navbar {
  background-color: #101951;
  height: 120px;
  display: flex;
  justify-content: flex-end; /* Adjust as needed */
  align-items: center; /* Align items to the top */
  background-image: url("SasuvikaConsultancyInc-1.png");
  background-repeat: no-repeat;
  image-resolution: 500px;
  position: relative; /* Add relative positioning */

}

.name {
  position: absolute; /* Add absolute positioning */
 /* padding-top: inherit;  Align to the top */
  left: 1cm; /* Align to the left */
}

.name h2 {
  margin: 0; /* Remove default margin */
  color: #f5f5f5;
}

.menu-bars {
  margin-left: 2rem;
  font-size: 2rem;
  background: none;
  padding-top: inherit;
}

.nav-menu {
  background-color: #478eb4;
  width: 200px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  right: -100%; /* Change left to right */
  transition: 850ms;
}

.nav-menu.active {
  right: 0; /* Change left to right */
  transition: 350ms;
}

.nav-text {
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 8px 0px 8px 16px;
  list-style: none;
  height: 60px;
}

/*
.name {
  font-size: 15px;
  color: #bfbbbb;
  font-family: myriad-pro, Arial;
}
*/
.nav-text a {
  text-decoration: none;
  color: #f5f5f5;
  font-size: 18px;
  width: 99%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 16px;
  border-radius: 4px;
}

.nav-text a:hover {
  background-color: #1f79aa;
}

.nav-menu-items {
  width: 100%;
}

.navbar-toggle {
  /* background-color: #829ca1; */
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: start;
  align-items: center;
  background-image: url("SasuvikaConsultancyInc.png");
}

span {
  margin-left: 16px;
}
